import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import Meta from '../../components/SEO/Meta';
import { mapStateToTags, getTags } from '../../actions/seo';
import { withConfig } from 'HOCs/withConfig/withConfig';
import ifConfig from 'HOCs/ifConfig/ifConfig';
import withRouter from 'HOCs/withRouter';

/**
 * @param {Function} mapPropsToQuery - Maps components properties to the SEO's tags query
 * @return {ReactComponent} Decorated component
 */
const withTags = (_module, mapPropsToQuery, { withHelmet = true } = {}) => Component => {
    const configWrapper = c => ({
        get: (...args) => args.reduce((v = {}, k) => v[k], c)
    });

    const getModule = (props = {}) => {
        return typeof _module === 'string' ? _module : _module(props);
    };

    class WithTags extends React.Component {
        static fetchData(...args) {
            const [dispatch, renderProps, reqProps, options] = args;
            const module = getModule({ ...renderProps, config: configWrapper(options.config) });

            return Promise.all([
                (Component.fetchData ? Component.fetchData(...args) : Promise.resolve()),
                dispatch(getTags(configWrapper(options.config), module, mapPropsToQuery(renderProps), reqProps))
            ]);
        }

        static propTypes = {
            __getTags: PropTypes.func.isRequired,
            __title: PropTypes.string,
            __description: PropTypes.string,
            __h1: PropTypes.string,
            __footer: PropTypes.array,
            __seoText: PropTypes.shape({
                title: PropTypes.string,
                text: PropTypes.string
            }),
            __config: PropTypes.shape({
                get: PropTypes.func.isRequired
            }).isRequired,
            __canonical: PropTypes.string,
            __schema: PropTypes.object,
            module: PropTypes.string
        };

        componentDidMount() {
            this.props.__getTags();
        }

        componentDidUpdate() {
            this.props.__getTags();
        }

        render() {
            const {
                __title,
                __description,
                __h1,
                __footer,
                __seoText,
                __canonical,
                __getTags, // eslint-disable-line no-unused-vars
                __config, // eslint-disable-line no-unused-vars
                __schema,
                module,
                ...props
            } = this.props;

            return (
                <React.Fragment>
                    { withHelmet && (
                        <Meta
                            module={ module }
                            title={ __title }
                            description={ __description }
                            schema={ __schema }
                        />
                    ) }
                    <Component
                        h1={ __h1 }
                        footer={ __footer }
                        seoText={ __seoText }
                        seoTitle={ __title }
                        seoDescription={ __description }
                        seoCanonical={ __canonical }
                        { ...props }
                    />
                </React.Fragment>
            );
        }
    }

    const mapStateToProps = (state, props) => {
        const module = getModule({ ...props, config: props.__config });
        const tags = mapStateToTags(props.__config, state, module, mapPropsToQuery(props)) || {};

        return {
            __h1: tags.h1,
            __title: tags.title,
            __description: tags.description,
            __footer: tags.footer,
            __seoText: tags.seoText,
            __canonical: tags.canonical,
            __schema: tags.schema,
            module
        };
    };
    const mapDispatchToProps = (dispatch, props) => {
        const module = getModule({ ...props, config: props.__config });

        return {
            __getTags: () => dispatch(getTags(props.__config, module, mapPropsToQuery(props)))
        };
    };

    const Mystique = hoistNonReactStatic(
        Object.assign(
            compose(
                withRouter,
                withConfig('__config'),
                connect(mapStateToProps, mapDispatchToProps)
            )(WithTags),
            {
                displayName: `withTags(${Component.displayName || Component.name})`
            }
        ),
        Component,
        { fetchData: true }
    );

    const FallBack = props => ( // eslint-disable-line react/no-multi-comp
        <React.Fragment>
            { withHelmet && (
                <Meta module={ module } />
            ) }
            <Component { ...props } />
        </React.Fragment>
    );

    return ifConfig(
        ['SEO', 'mystique'],
        { [true]: Mystique },
        hoistNonReactStatic(FallBack, Component)
    );
};

export default withTags;
