import { isActive, isPending } from 'Helpers/adActionStatus';
import { getItemSubsetData } from 'Helpers/item';
import isNumber from 'lodash/isNumber';
import { isEmpty } from 'Helpers/objects';
import {
    __MONET_TRANSITION_PARAMS,
    __FEATURE_TYPE_MAP,
    __FEATURE_ALL,
    __FEATURE_AD_UPGRADE,
    __FEATURE_BTT,
    __FEATURE_AUTO_BOOST,
    __FEATURE_LIMITS,
    __FEATURE_USER_PREMIUM,
    __FEATURE_MULTI_LOCATION,
    __FEATURE_TYPE_LIMIT,
    __FEATURE_TYPE_MULTI_LOCATION
} from 'Constants/monetizers';

const prepareAdSubDataModel = (adModel, localStorage) => {
    const itemParams = getItemSubsetData(adModel);

    localStorage.setItem(__MONET_TRANSITION_PARAMS, JSON.stringify(itemParams));
};

export const prepareAdLimitModel = (isAdLimited, adModel, localStorage) => {
    const __POSTING_LIMIT_DRAFT = 'postingLimitDraft';
    const postingLimitDraft = adModel.monetizationInfo.limits;

    postingLimitDraft.isAdLimited = isAdLimited;
    postingLimitDraft.id = adModel.id;
    localStorage.setItem(__POSTING_LIMIT_DRAFT, JSON.stringify(postingLimitDraft));
    prepareAdSubDataModel(adModel, localStorage);

    return postingLimitDraft;
};

export const getSavedAdLimitModel = localStorage => {
    return JSON.parse(localStorage.getItem('postingLimitDraft'));
};

export const routeToPostingLimits = (adLimitModel, router, merge, isMultiLocationAd) => {
    if (adLimitModel) {
        const params = merge ? '?merge=true' : '';

        if (adLimitModel.isAdLimited) {
            if (adLimitModel.free_limit_available && adLimitModel.free_count >= 0) {
                router.push(`/payments/packages/consume/free/success${params}`);
            }
            else if (adLimitModel.paid_available) {
                router.push(`/payments/packages/consume/${isMultiLocationAd ? __FEATURE_TYPE_MULTI_LOCATION : __FEATURE_TYPE_LIMIT}${params}`);
            }
            else {
                router.push(`/payments/choosePackage/single/${isMultiLocationAd ? __FEATURE_MULTI_LOCATION : __FEATURE_LIMITS}${params}`);
            }
        }
        else if (adLimitModel.free_count >= 0) {
            router.push(`/payments/packages/consume/free/success${params}`);
        }
        else {
            router.push(`/payments/packages/consume/unlimited/success${params}`);
        }
    }
};

export const routeToPackageConsumption = (router, adModel, localStorage) => {
    prepareAdSubDataModel(adModel, localStorage);
    router.push('/payments/packages/consume/boost');
};

export const canBeFeatured = (loggedUser, { monetizationInfo, status, user_id: itemUserId }) => {
    const radix = 10;

    return monetizationInfo
        && loggedUser
        && parseInt(loggedUser.id, radix) === parseInt(itemUserId, radix)
        && (isActive(status) || isPending(status));
};

export const isAdPostable = limitInfo => {
    return !isEmpty(limitInfo) && (limitInfo.free_limit_available || limitInfo.paid_available);
};

export const checkDiscount = pkg => {
    return pkg.price.amount != 0
        && pkg.price.previousAmount != 0
        && pkg.price.amount < pkg.price.previousAmount;
};

export const calculateDiscount = (prevPrice, currentPrice) => {
    return Math.round((prevPrice - currentPrice) / prevPrice * 100, 0); // eslint-disable-line no-magic-numbers
};

export const addDaysToDate = days => {
    const date = new Date();

    date.setDate(date.getDate() + days);
    return date;
};

export const addDaysToDateWithOptions = days => {
    const currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + days);

    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);

    return formattedDate.replace(',', '').replace(' ', "' ");
};

export const isLocationInfoAvailable = locationInfo => {
    let locationInfoAvailable = false;

    if (locationInfo.additionalTrackingAttributes === null) {
        const { cityId, regionId, subRegionId, cityObj, regionObj, subRegionObj } = locationInfo;

        locationInfoAvailable = (cityId === 0 && regionId === 0)
            || (cityId && cityObj)
            || (regionId && regionObj)
            || (subRegionId && subRegionObj);
    }
    return locationInfoAvailable;
};

export const buildAdditionalAttributes = (locationInfo, locationProps, addOrderId = false) => {
    // add tracking paramater for packages bought at country level
    const additionalAttributes = {};
    const categoryL2Obj = locationProps.allCategories[locationProps?.item?.categoryId];
    const categoryL1Obj = locationProps.allCategories[categoryL2Obj?.parent_id];

    if (categoryL1Obj) {
        additionalAttributes.category_level1_id = categoryL1Obj?.id;
        additionalAttributes.category_level1_name = categoryL1Obj?.name;
    }
    additionalAttributes.category_level2_id = categoryL2Obj?.id;
    additionalAttributes.category_level2_name = categoryL2Obj?.name;

    if (locationInfo.cityObj) {
        additionalAttributes.city_id = locationInfo?.cityObj.id;
        additionalAttributes.city_name = locationInfo?.cityObj.name;
    }
    if (locationInfo.regionObj) {
        additionalAttributes.state_id = locationInfo.regionObj.id;
        additionalAttributes.state_name = locationInfo.regionObj.name;
    }
    if (locationInfo.subRegionObj) {
        additionalAttributes.neighbourhood_id = locationInfo.subRegionObj.id;
        additionalAttributes.neighbourhood_name = locationInfo.subRegionObj.name;
    }
    if (locationProps?.item?.adId) {
        additionalAttributes.item_id = locationProps.item.adId;
    }
    if (locationProps?.item?.status) {
        additionalAttributes.item_status = locationProps.item.status;
    }
    if (locationProps?.item?.imageCount) {
        additionalAttributes.images_count = locationProps?.item?.imageCount;
    }
    if (addOrderId) {
        // SINCE ORDER_ID WAS PRESENT ONLY FOR MULTI SELECTED PACKAGE, THIS FIX IS IMPORTANT FOR ALL TYPES OF PACKAGES TO PASS ORDER_ID
        if (locationInfo?.selectedPackage && locationInfo?.selectedPackage?.orderId) {
            additionalAttributes.order_id = locationInfo.selectedPackage.orderId;
        }
        else if (locationInfo?.selectedMultiplePackage && locationInfo?.selectedMultiplePackage?.orderId) {
            additionalAttributes.order_id = locationInfo.selectedMultiplePackage.orderId;
        }
    }
    return additionalAttributes;
};

function buildLocationParams(locationObj) {
    const { cityId, stateId, neighbourhoodId } = locationObj;

    return {
        cityId: isNumber(parseInt(cityId, 10)) ? parseInt(cityId, 10) : '',
        stateId: isNumber(parseInt(stateId, 10)) ? parseInt(stateId, 10) : '',
        neighbourhoodId: isNumber(parseInt(neighbourhoodId, 10)) ? parseInt(neighbourhoodId, 10) : ''
    };
}

export const fetchLocationDetails = (self, getLocationsById) => {
    let locationFetched = false;
    const { monetTransitionParams, cityObj, regionObj, subRegionObj } = self;
    const { cityId, stateId, neighbourhoodId } = buildLocationParams(monetTransitionParams.locations);

    if (cityId && !cityObj) {
        getLocationsById(cityId);
        locationFetched = true;
    }
    if (stateId && !regionObj) {
        getLocationsById(stateId);
        locationFetched = true;
    }
    if (neighbourhoodId && !subRegionObj) {
        getLocationsById(neighbourhoodId);
        locationFetched = true;
    }
    return locationFetched;
};

export const getPackageGroup = (featureType, origin, isRevamp = false) => {
    let packageGroup = __FEATURE_TYPE_MAP[featureType] || featureType;

    if (featureType === __FEATURE_AD_UPGRADE) {
        if (origin !== 'posting') {
            packageGroup += `,${__FEATURE_BTT}`;
        }
    }
    else if (featureType === __FEATURE_ALL && isRevamp) {
        packageGroup = `${__FEATURE_LIMITS},${__FEATURE_AD_UPGRADE},${__FEATURE_BTT},${__FEATURE_AUTO_BOOST},${__FEATURE_USER_PREMIUM},${__FEATURE_MULTI_LOCATION}`;
    }
    else if (featureType === __FEATURE_ALL) {
        packageGroup = `${__FEATURE_LIMITS},${__FEATURE_AD_UPGRADE},${__FEATURE_BTT},${__FEATURE_AUTO_BOOST},${__FEATURE_USER_PREMIUM}`;
    }

    return packageGroup;
};
