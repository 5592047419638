import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import Listing from './Listing';
import JsonLd from './JsonLd';

const Meta = ({ module, title, description, schema }) => {
    if (!title || !description) {
        switch (module) {
            case 'listing':
                return <Listing />;
            default:
                // XXX: default meta & title fallback
                break;
        }
    }

    const isSchemaValid = schema && Object.keys(schema).length > 0;

    return (
        <>
            <Helmet>
                <title>{ title }</title>
                <meta name="description" content={ description } />
            </Helmet>
            { isSchemaValid && <JsonLd data={ schema } /> }
        </>
    );
};

Meta.propTypes = {
    module: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    schema: PropTypes.object
};

export default Meta;
