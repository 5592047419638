import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const JsonLd = ({ data }) => {
    const script = [
        {
            type: 'application/ld+json',
            innerHTML: data ? JSON.stringify(data).replace(/</g, '\\u003c') : JSON.stringify(data)
        }
    ];

    if (data) {
        return (
            <Helmet script={ script } />
        );
    }
    return null;
};

JsonLd.propTypes = {
    data: PropTypes.object
};

export default JsonLd;
